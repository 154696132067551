import React, { useEffect, useState } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { fetchItems } from './api';
import ItemList from './Components/ItemList';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NewsLetter from './Components/NewsLetter';

const Home: React.FC = () => {

    const [items, setItems] = useState([]);
    const [altura, setAltura] = useState(false); 
    useEffect(() => {
        const getItems = async () => {
        try {
            const data = await fetchItems();
            setItems(data);
            setAltura(true);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
        };

        getItems();
    }, []);

  const empresas = [
    { id: 1, string: "Nuestros-clientes-winters-logo.png" },
    { id: 2, string: "Nuestros-clientes-veta-dorada-logo.png" },
    { id: 3, string: "Nuestros-clientes-paraiso-logo.png" },
    { id: 4, string: "Nuestros-clientes-San-lorenzo-logo.png" },
    { id: 5, string: "Nuestros-clientes-sierra-antapite-logo.png" },
    { id: 6, string: "Nuestros-clientes-orazul-logo.png" },
    { id: 7, string: "Nuestros-clientes-precotex-logo.png" },
    { id: 8, string: "Nuestros-clientes-molitalia-logo.png" },
    { id: 9, string: "Nuestros-clientes-Cumbra-logo.png" },
    { id: 10, string: "Nuestros-clientes-ks-logo.png" },
    { id: 11, string: "Nuestros-clientes-marsa-logo.png" },
    { id: 12, string: "Nuestros-clientes-Gandules-logo.png" },
    { id: 13, string: "Nuestros-clientes-CALQUIPA-logo.png" },
    { id: 14, string: "Nuestros-clientes-fenix-logo.png" },
    { id: 15, string: "Nuestros-clientes-Abengoa-logo.png" },
    { id: 16, string: "Nuestros-clientes-kallpa-logo.png" },
    { id: 17, string: "Nuestros-clientes-alicorp-logo.png" },
    { id: 18, string: "Nuestros-clientes-gloria-logo.png" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };

  const settingsMobile = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2
  };

  
  const settingsMobileLow = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings_home = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const useIsMobile = (breakpoint = 768) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < breakpoint);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Limpia el evento cuando el componente se desmonta
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [breakpoint]);
  
    return isMobile;
  };

  const useIsMobileLow = (breakpoint = 391) => {
    const [isMobileLow, setIsMobileLow] = useState(window.innerWidth < breakpoint);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobileLow(window.innerWidth < breakpoint);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Limpia el evento cuando el componente se desmonta
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [breakpoint]);
  
    return isMobileLow;
  };


  const isMobile = useIsMobile();
  const isMobileLow = useIsMobileLow();
  const settingsSlider = isMobileLow? settingsMobileLow : isMobile ? settingsMobile : settings;

  return (
    <>
      <div className="page-wrapper">
        <Header />
        <section className="main-slider clearfix home">

              <Slider {...settings_home}>
                <div className="item swiper-slide">
                    <div className="image-layer slider1Home" >
   
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="main-slider__content">
                                    <h2 className="main-slider__title">Garantizamos <span className="colorCalidad">Calidad</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" item swiper-slide">
                    <div className="image-layer slider2Home" >

                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="main-slider__content">
                                    <h2 className="main-slider__title">Atención <span className="colorCalidad">Personalizada</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item swiper-slide">
                    <div className="image-layer slider3Home" >
     
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="main-slider__content">
                                    <h2 className="main-slider__title">Soluciones <span className="colorCalidad">Exactas</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </Slider>
        </section>
        <section className="feature-one">
          <div className="container">
            <div className="feature-one__inner">

              <div className="row">
                <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms" style={{textAlign:"center"}}>
                  <div className="feature-one__single">

                    <div className="feature-one__single-inner">
                      <img src="assets/images/resources/001.-Home-Pagina-Web-LSA-mayor-durabilidad.png" alt="" className="imageResponsive" />
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms" style={{textAlign:"center"}}>
                  <div className="feature-one__single">

                    <div className="feature-one__single-inner">
                      <img src="assets/images/resources/001.-Home-Pagina-Web-LSA-experiencia-de-6-anos.png" alt=""  className="imageResponsive" />

                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms" style={{textAlign:"center"}}>
                  <div className="feature-one__single">

                    <div className="feature-one__single-inner">
                      <img src="assets/images/resources/001.-Home-Pagina-Web-LSA-productos-a-medida.png" alt=""  className="imageResponsive" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="project-two">
          <div className="project-two__container">
            <div className="section-title text-center">

              <h2 className="section-title__title">NUESTROS PRODUCTOS</h2>
            </div>

            <div className="project-two__bottomHome">
                <ItemList items={items} altura={altura} isHome={true}/>
            </div>
            <div className="row viewMore">
            <div className="main-menu__btn-box">
                          <a href="/products" className="thm-btn main-menu__btn"> VER MÁS</a>
                        </div>
                        </div>
          </div>
        </section>
        

        <section className="services-one">
          
          <div className="container">
            <div className="services-one__top">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="services-one__top-left">
                    <div className="section-title text-center">

                      <h2 className="section-title__title">NUESTROS CLIENTES</h2>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                <Slider {...settingsSlider}>
                  {empresas.map(item => (
                      <div className="item brand-one__single" key={item.id}>
                      <div className="brand-one__img">
                        <img src={`assets/images/business/${item.string}`} alt="" />
                      </div>
                    </div>
                    ))}
              </Slider>

                </div>

              </div>

            </div>
          </div>
        </section>

      <NewsLetter />
       

      <Footer/>
      </div>
    </>
  );
}

export default Home;
