import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faDownload } from '@fortawesome/free-solid-svg-icons';
import './DocumentList.css';
import { BASE_URL_DOCUMENTS } from './Constants';

interface Documento {
  nombre_archivo: string;
  url_archivo: string;
}

interface DocumentListProps {
  documentos: Documento[];
}

const DocumentList: React.FC<DocumentListProps> = ({ documentos }) => {



    return (
      <div>
        {documentos.length > 0 && (
          <ul className="document-list">
            {documentos.map((documento, index) => (
              <li key={index} className="document-list-item" >
                 <a href={BASE_URL_DOCUMENTS +  documento.url_archivo} target="_blank" rel="noopener noreferrer" className="document-link">
                <span className="document-name">
                  <FontAwesomeIcon icon={faFilePdf} size="1x" color="red" />
                  {documento.nombre_archivo}
                </span>
                <span className="document-actions">
                  <FontAwesomeIcon icon={faDownload} size="1x" />
                </span>
              </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };
  
export default DocumentList;
