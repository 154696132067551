
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://lsaperu.com/backend/public/api/',
});

export const fetchItems = async () => {
  const response = await api.get('/productos');
  return response.data;
};

export const fetchItemsAll = async () => {
  const response = await api.get('/productos-all');
  return response.data;
};