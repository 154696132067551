import React, { useState } from 'react';

const Header: React.FC = () => {
  const [isMobileNavExpanded, setMobileNavExpanded] = useState(false);

  const toggleMobileNav = (e: React.MouseEvent) => {
    e.preventDefault();
    setMobileNavExpanded(!isMobileNavExpanded);
  };

  return (
    <>
        <header className="main-header clearfix">
          <div className="main-header__top">
            <div className="main-header__top-inner">
              <div className="main-header__top-address">
                <a href="/"><img src="/assets/images/resources/001.LOGO.LSA.PERU.png" alt=""
                  style={{ width: '30%' }} /></a>
              </div>
              <div className="main-header__top-right">
                <ul className="list-unstyled main-header__top-menu">
                  <li>
                    <img src="/assets/images/icon/001.-Home-Pagina-Web-LSA-icono-telefono.png" alt=""
                      style={{ width: '14px', marginRight: '8px' }} />                      <a href="tel:+51945425276">+51 945 425 276</a>
                  </li>
                  <li>
                    <img src="/assets/images/icon/001.-Home-Pagina-Web-LSA-icono-mail.png" style={{ width: '14px', marginRight: '8px' }} />
                    <a href="mailto:ventas@lsaperu.com">ventas@lsaperu.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <nav className="main-menu clearfix">
            <div className="main-menu__wrapper clearfix">
              <div className="main-menu__wrapper-inner clearfix">
                <div className="mobile-logo__toggler">
                <a href="/"><img src="/assets/images/resources/001.LOGO.LSA.PERU.png" alt=""
                  className="logoMain" /></a>
                </div>
                <div className="main-menu__left">
                  <div className="main-menu__main-menu-box">
                    <a href="#" className="mobile-nav__toggler" onClick={toggleMobileNav}><i className="fa fa-bars"></i></a>
                    <ul className="main-menu__list">
                      <li className="dropdown current megamenu">
                        <a href="/">INICIO </a>
                      </li>
                      <li>
                        <a href="/about">NOSOTROS</a>
                      </li>
                      <li className="dropdown">
                        <a href="/products">NUESTROS PRODUCTOS</a>
                      </li>
                      <li className="dropdown">
                        <a href="/solutions">SOLUCIONES</a>
                      </li>
                      <li>
                        <div className="main-menu__btn-box">
                          <a href="/contact" className="thm-btn main-menu__btn"> CONTÁCTANOS</a>
                        </div>
                      </li>

                    </ul>

                  </div>

                </div>
              </div>
            </div>
          </nav>
        </header>

        <div className={`mobile-nav__wrapper  ${isMobileNavExpanded ? 'expanded' : ''}`}>
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        <div className="mobile-nav__content">
            <span className="mobile-nav__close mobile-nav__toggler" onClick={toggleMobileNav}><i className="fa fa-times"></i></span>

            <div className="logo-box">
                <a href="index.html" aria-label="logo image"><img src="/assets/images/resources/001.LOGO.LSA.PERU.png" width="143"
                        alt="" /></a>
            </div>
           
            <div className="mobile-nav__container">

            <ul className="main-menu__list">
                      <li className="dropdown current megamenu">
                        <a href="/">INICIO </a>
                      </li>
                      <li>
                        <a href="/about">NOSOTROS</a>
                      </li>
                      <li className="dropdown">
                        <a href="/products">NUESTROS PRODUCTOS</a>
                      </li>
                      <li className="dropdown">
                        <a href="/solutions">SOLUCIONES</a>
                      </li>
                      <li className="dropdown">
                        <a href="/contact">CONTÁCTANOS</a>
                      </li>

                    </ul>

            </div>
            

            <ul className="mobile-nav__contact list-unstyled">
                <li>
                    <i className="fa fa-envelope"></i>
                    <a href="mailto:ventas@lsaperu.com">ventas@lsaperu.com</a>
                </li>
                <li>
                    <i className="fa fa-phone-alt"></i>
                    <a href="tel:+51945425276">+51 945 425 276</a>
                </li>
            </ul>
            <div className="mobile-nav__top">
                <div className="mobile-nav__social">
                <a href="https://www.facebook.com/profile.php?id=100088485326446" target='_blank'><i className="fab fa-facebook"></i></a>
                <a href="https://www.linkedin.com/company/91704465/admin/feed/posts/"  target='_blank'><i className="fab fa-linkedin"></i></a>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default Header;