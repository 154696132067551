import React, { useState } from 'react';
import { BASE_URL } from '../Constants';

interface Item {
    id: number;
    nombre: string;
    descripcion: string;
    fotos: string;
}

interface ItemListProps {
    items: Item[];
    altura: boolean;
    isHome: boolean;
}

const truncateString = (str: string, num =200) => {
    if (str.length > num) {
      return str.slice(0, num) + '...';
    } else {
      return str;
    }
  };

const ItemList: React.FC<ItemListProps> = ({ items, altura, isHome }) => {

    return (
        <div className="row" >
            {items.map((item: any, index: number) => {
                // Determinar las clases basadas en la posición en la fila
                let columnIndex = index % 3;
                let classes = "col-xl-4 col-lg-6 col-md-6 filter-item";

                // Añadir clases adicionales basadas en la posición en la fila
                if (columnIndex === 0) {
                    classes += " comm res";
                } else if (columnIndex === 1) {
                    classes += " comm ind";
                } else if (columnIndex === 2) {
                    classes += " res ind";
                }

                return (
                    <div key={index} className={classes}>
                        <div className="project-two__single">
                            <div className="project-two__img-box">
                                <div className={isHome ? "project-two__imgHome" : "project-two__img" }>

                                {isHome ? <a href={"/producto/"+item.id} ><img src={BASE_URL+item.fotos} alt={item.nombre} /></a> : <img src={BASE_URL+item.fotos} alt={item.nombre} />}
                                </div>
                                {!isHome && <div className="project-two__content">
                                    <div className="project-two__icon">
                                        <img src="" alt="" />
                                            <p className={isHome? "project-two__sub-titleHome" : "project-two__sub-title" }>{item.nombre}</p>
                                        <div className={isHome? "main-menu__btn-boxNuestrosProducHome" : "main-menu__btn-boxNuestrosProduc" }>
                                            <a href={"/producto/"+item.id} className="thm-btn main-menu__btn">Ver más</a>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ItemList;