import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Products from './Products';
import Solutions from './Solutions';
import Contact from './Contact';
import Login from './Login';
import AdminPanel from './AdminPanel';
import ListaProductos from './ListaProductos';
import ListaUsuarios from './ListaUsuarios';
import Usuario from './Usuario';
import ProductoCrud from './ProductoCrud';
import Producto from './Producto';
import ListaSubs from './ListaSubs';
import ListaCotizaciones from './ListaCotizaciones';
import ListaSoluciones from './ListaSoluciones';
import SolucionCrud from './SolucionCrud';
import ListaDocumentos from './ListaDocumentos';
import DocumentoCrud from './DocumentoCRUD';

const App: React.FC = () => {
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/admin/productos" element={<ListaProductos />} />
        <Route path="/admin/producto" element={<ProductoCrud />} />
        <Route path="/admin/producto/:productId" element={<ProductoCrud />} />
        <Route path="/admin/usuarios" element={<ListaUsuarios />} />
        <Route path="/admin/usuario" element={<Usuario />} />
        <Route path="/admin/usuario/:userId" element={<Usuario />} />
        <Route path="/producto/:productId" element={<Producto />} />
        <Route path="/admin/subs" element={<ListaSubs />} />
        <Route path="/admin/cotizaciones" element={<ListaCotizaciones />} />
        
        <Route path="/admin/soluciones" element={<ListaSoluciones />} />
        <Route path="/admin/solucion" element={<SolucionCrud />} />
        <Route path="/admin/solucion/:solucionId" element={<SolucionCrud />} />
        <Route path="/admin/documentos/:productId" element={<ListaDocumentos />} />
        <Route path="/admin/documento/:productId/:documentId" element={<DocumentoCrud />} />
      </Routes>
    </Router>
  );
}

export default App;