import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBar from './Components/SideBar';
import Header from './Components/Header';
import Footer from './Components/Footer';

interface User {
  id: number;
  name: string;
  email: string;
  email_verified_at: null | string;
  created_at: string;
  updated_at: string;
}

const AdminPanel: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
        setUser(JSON.parse(token));
    }
  }, [navigate]);

  if (!user) return null; 

  return (
    <div className="page-wrapper">

    <Header />
    <div className="d-flex">
        <SideBar />
    <div className="flex-grow-1 p-3">
        <div className="container mt-5">
        <h1>Bienvenido al Panel de Administración, {user.name}</h1>
        <div className="mt-4">
            <div className="card">
            <div className="card-header">Información del Usuario</div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Email: {user.email}</li>
            </ul>
            </div>
        </div>
        </div>
    </div>
  </div>
  <Footer/>
      </div>  
  );
};

export default AdminPanel;