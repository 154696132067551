import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import SideBar from './Components/SideBar';
import { BASE_URL_DOCUMENTS } from './Constants';
import Footer from './Components/Footer';
import Header from './Components/Header';

interface DocumentProps {
  productId?: number; // Id del producto relacionado, opcional
  documentId?: number;
}

interface User {
  id: number;
  name: string;
  email: string;
  email_verified_at: null | string;
  created_at: string;
  updated_at: string;
}

const DocumentoCrud: React.FC<DocumentProps> = () => {
  const { productId, documentId } = useParams();
  const [nombre, setNombre] = useState('');
  const [productoId, setProductoId] = useState('');
  const [archivo, setArchivo] = useState<File | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const [archivoURL, setArchivoURL] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      setUser(JSON.parse(token));
    }
  }, [navigate]);

  useEffect(() => {
    return () => {
      if (archivoURL) URL.revokeObjectURL(archivoURL);
    };
  }, [archivoURL]);

  useEffect(() => {
    if (documentId && documentId != '0') {
      fetchDocumento(documentId);
    }
  }, [documentId]);

  const fetchDocumento = async (id: string) => {
    try {
      const response = await fetch(`https://lsaperu.com/backend/public/api/documento/${id}`);
      if (response.ok) {
        const data = await response.json();
        setNombre(data.nombre_archivo);
        setProductoId(data.url_archivo);
      } else {
        console.error('Error al cargar los detalles del documento');
      }
    } catch (error) {
      console.error('Error al cargar los detalles del documento:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('nombre_archivo', nombre);
    if (archivo && archivo instanceof File) {
      formData.append('documento', archivo);
    }
    if (productId) {
      formData.append('producto_id', productId);
    }

    const url = documentId != '0' ? `https://lsaperu.com/backend/public/api/documentos-update/${documentId}` : 'https://lsaperu.com/backend/public/api/documentos';
    const method = 'POST';

    try {
      const response = await fetch(url, {
        method,
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        navigate(`/admin/documentos/${productId}`);
      } else {
        console.error('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setArchivo(file);
      setArchivoURL(URL.createObjectURL(file));
    }
  };

  if (!user) return null;

  return (
    <div className="page-wrapper">
      <Header />
      <div className="d-flex">
        <SideBar />
        <div className="flex-grow-1 p-3">
          <div className="container mt-5">
            <div>
              <h2>{documentId && documentId != '0'? 'Editar Documento' : 'Crear Nuevo Documento'}</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formNombre">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" placeholder="Ingrese el nombre del documento" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formArchivo">
                  <Form.Label>Archivo</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} accept="application/pdf" />
                  <hr />
                  {archivoURL && <embed src={archivoURL || ""} style={{ width: "200px" }}  />}
                  {!archivoURL && productoId && <embed src={BASE_URL_DOCUMENTS+productoId} style={{ width: "200px", height: "200px" }} type="application/pdf" />}
                </Form.Group>
                <Button variant="primary" type="submit">
                  {documentId && documentId != '0' ? 'Actualizar Documento' : 'Crear Documento'}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DocumentoCrud;
