import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate,useParams } from 'react-router-dom';
import SideBar from './Components/SideBar';
import { BASE_URL } from './Constants';
import Footer from './Components/Footer';
import Header from './Components/Header';

interface ProductProps {
  solucionId?: number; // Id del producto existente, opcional
}

interface User {
    id: number;
    name: string;
    email: string;
    email_verified_at: null | string;
    created_at: string;
    updated_at: string;
  }

const SolucionCrud: React.FC<ProductProps> = () => {
  const { solucionId } = useParams();
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [caracteristicas, setCaracteristicas] = useState<string[]>([]);
  const [beneficios, setBeneficios] = useState<string[]>([]);
  const [nuevaCaracteristica, setNuevaCaracteristica] = useState('');
  const [nuevaBeneficio, setNuevaBeneficio] = useState('');
  const [fotos, setFotos] = useState<File | null>(null); // Cambiado a tipo File
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const [fotoURL, setFotoURL] = useState<string | null>(null); // Estado para la URL de previsualización

  const [fotosMobile, setFotosMobile] = useState<File | null>(null); // Cambiado a tipo File
  const [fotoURLMobile, setFotoURLMobile] = useState<string | null>(null); // Estado para la URL de previsualización

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      // Aquí asumimos que guardaste los datos del usuario en localStorage o los recuperas de alguna API
        setUser(JSON.parse(token));
    }
  }, [navigate]);

  useEffect(() => {
    // Liberar la URL de la memoria cuando el componente se desmonte
    return () => {
      if (fotoURL) URL.revokeObjectURL(BASE_URL+fotoURL);
    };
  }, [fotoURL]);

  useEffect(() => {
    // Si productId está presente, cargar los detalles del producto
    if (solucionId) {
      fetchProducto(solucionId);
    }
  }, [solucionId]);

  const fetchProducto = async (id: string) => {
    try {
      const response = await fetch(`https://lsaperu.com/backend/public/api/soluciones/${id}`);
      if (response.ok) {
        const data = await response.json();
        setNombre(data.titulo);
        setDescripcion(data.descripcion);
        setCaracteristicas(data.caracteristicas ? data.caracteristicas.split('@') : []);
        setBeneficios(data.beneficios ? data.beneficios.split('@') : []);
        setFotos(data.fotoweb);
        setFotosMobile(data.fotomobile);
      } else {
        console.error('Error al cargar los detalles del producto');
      }
    } catch (error) {
      console.error('Error al cargar los detalles del producto:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('descripcion', descripcion);
    formData.append('caracteristicas', caracteristicas.join('@'));
    formData.append('beneficios', beneficios.join('@'));
    if (fotos && fotos instanceof File) {
      formData.append('fotoweb', fotos);
    }

    if (fotosMobile && fotosMobile instanceof File) {
        formData.append('fotomobile', fotosMobile);
    }
    
    const url = solucionId ? `https://lsaperu.com/backend/public/api/soluciones-update/${solucionId}` : 'https://lsaperu.com/backend/public/api/soluciones';
    const method ='POST';

    try {
      const response = await fetch(url, {
        method,
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        navigate("/admin/soluciones");
      } else {
        console.error('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
    }
  };


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFotos(file);
      setFotoURL(URL.createObjectURL(file)); // Crear URL de previsualización
    }
  };

  const handleFileChangeMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFotosMobile(file);
      setFotoURLMobile(URL.createObjectURL(file)); // Crear URL de previsualización
    }
  };

  const handleNuevaCaracteristicaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNuevaCaracteristica(e.target.value);
  };

  const handleNuevaBeneficiosChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNuevaBeneficio(e.target.value);
  };

  const handleAgregarCaracteristica = () => {
    if (nuevaCaracteristica.trim()) {
      setCaracteristicas([...caracteristicas, nuevaCaracteristica.trim()]);
      setNuevaCaracteristica('');
    }
  };

  const handleAgregarBeneficio = () => {
    if (nuevaBeneficio.trim()) {
      setBeneficios([...beneficios, nuevaBeneficio.trim()]);
      setNuevaBeneficio('');
    }
  };

  const handleEliminarCaracteristica = (index: number) => {
    setCaracteristicas(caracteristicas.filter((_, i) => i !== index));
  };
  const handleEliminarBeneficio = (index: number) => {
    setBeneficios(beneficios.filter((_, i) => i !== index));
  };
  if (!user) return null;

  return (

    <div className="page-wrapper">
    <Header />
    <div className="d-flex">
        <SideBar />
    <div className="flex-grow-1 p-3">
        <div className="container mt-5">
        <div>
      <h2>{solucionId ? 'Editar Solucion' : 'Crear Nueva Solucion'}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formNombre">
          <Form.Label>Nombre</Form.Label>
          <Form.Control type="text" placeholder="Ingrese el nombre del producto" value={nombre} onChange={(e) => setNombre(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formDescripcion">
          <Form.Label>Descripción</Form.Label>
          <Form.Control type="text" placeholder="Ingrese la descripción del producto" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBeneficios">
                <Form.Label>Beneficios <small style={{color:'red'}}>Formato: *SUBTITULO:* CONTENIDO</small></Form.Label>
                <div className="d-flex mb-2">
                  <Form.Control type="text" placeholder="Ingrese un Beneficio" value={nuevaBeneficio} onChange={handleNuevaBeneficiosChange} />
                  <Button variant="secondary" onClick={handleAgregarBeneficio} className="ms-2">Agregar</Button>
                </div>
                <ul>
                  {beneficios.map((caracteristica, index) => (
                    <li key={index} className="d-flex justify-content-between align-items-center">
                      {caracteristica}
                      <Button variant="danger" size="sm" onClick={() => handleEliminarBeneficio(index)}>Eliminar</Button>
                    </li>
                  ))}
                </ul>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formCaracteristicas">
                <Form.Label>Características</Form.Label>
                <div className="d-flex mb-2">
                  <Form.Control type="text" placeholder="Ingrese una característica" value={nuevaCaracteristica} onChange={handleNuevaCaracteristicaChange} />
                  <Button variant="secondary" onClick={handleAgregarCaracteristica} className="ms-2">Agregar</Button>
                </div>
                <ul>
                  {caracteristicas.map((caracteristica, index) => (
                    <li key={index} className="d-flex justify-content-between align-items-center">
                      {caracteristica}
                      <Button variant="danger" size="sm" onClick={() => handleEliminarCaracteristica(index)}>Eliminar</Button>
                    </li>
                  ))}
                </ul>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formFotos">
              <Form.Label>Foto Web</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
              <hr />
              {!fotoURL && fotos && <img src={BASE_URL+fotos} style={{ width: "200px" }} alt="Previsualización" />}
              {fotoURL && <img src={fotoURL} style={{ width: "200px" }} alt="Previsualización" />}
            </Form.Group>
        
            <Form.Group className="mb-3" controlId="formFotos">
              <Form.Label>Foto Mobile</Form.Label>
              <Form.Control type="file" onChange={handleFileChangeMobile} />
              <hr />
              {!fotoURLMobile && fotosMobile && <img src={BASE_URL+fotosMobile} style={{ width: "200px" }} alt="Previsualización" />}
              {fotoURLMobile && <img src={fotoURLMobile} style={{ width: "200px" }} alt="Previsualización" />}
            </Form.Group>

        <Button variant="primary" type="submit">
          {solucionId ? 'Actualizar Solucion' : 'Crear Solucion'}
        </Button>
      </Form>
    </div>
    </div>
        </div>
    </div>    
 <Footer/>
 </div>
    
  );
};

export default SolucionCrud;
