import React, { useEffect, useState } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import NewsLetter from './Components/NewsLetter';
import Cotizar from './Components/Cotiza';
import { BASE_URL } from './Constants';

const Solutions: React.FC = () => {

    const [productos, setProductos] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchProductos();
      }, []);

    const fetchProductos = async () => {
        try {
          const response = await fetch('https://lsaperu.com/backend/public/api/soluciones/all');
          if (response.ok) {
            const data = await response.json();
            setProductos(data);
          } else {
            console.error('Error al cargar las soluciones');
          }
        } catch (error) {
          console.error('Error al cargar los soluciones:', error);
        } finally {
          setLoading(false);
        }
      };

    const useIsMobile = (breakpoint = 768) => {
        const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);
      
        useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth < breakpoint);
          };
      
          window.addEventListener('resize', handleResize);
      
          // Limpia el evento cuando el componente se desmonta
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, [breakpoint]);
      
        return isMobile;
      };
      const formatText = (text: string) => {
        const regex = /\*([^*]+)\*/g;
        const parts = text.split(regex);
        return parts.map((part, index) => 
            index % 2 === 1 ? <strong key={index}>{part}</strong> : part
        );
    };
      const isMobile = useIsMobile();

  return (
    <>

      <div className="page-wrapper">
        <Header />


        <section className="main-slider clearfix">
            <div className="swiper-container thm-swiper__slider">
                <div className="swiper-wrapper">

                    <div className="swiper-slide">
                        <div className="image-layer slider1Soluciones">
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="main-slider__content">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
        </section>

        <section className="work-detailsContactanos">
            <div className="cuestomTitle">
                SOLUCIONES
            </div>
          
        </section>

        <section className="work-detailsSoluciones3">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                        
                        </div>
                    </div>
                    {productos.map((item: any) => {
                        let beneficios = item.beneficios ? item.beneficios.split('@') : [];
                        let caracteristica = item.caracteristicas ? item.caracteristicas.split('@') : [];

                        return (   
                            <>
                        <div className="work-detailsContacto__content">
                            <div className="row">
                            
                                <div className="col-xl-12 col-lg-12">
                                    <div className="work-detailsContacto__content-leftSol">
                        
                                    <h2 className="work-details__text-1"><span className="colorCalidad">{item.titulo}</span></h2>
                                        <p className="work-details__textsolucion-4">{item.descripcion}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    {beneficios.length > 0  &&  (<><div className="col-xl-12">
                                <div className="section-titleProductos ">
                                    <h2 className="section-title__titleProducSol">Beneficios de nuestro servicio:
                                    </h2>
                                </div>

                         </div>
                            <p className="section-title__titleProducSoluc">
                                {beneficios.map((caracteristica: any, index: any) => (
                                <li> {formatText(caracteristica)}</li>
                            ))}
                            </p></>
                        )}

                        {caracteristica.length > 0  &&  (<><div className="col-xl-12">
                                <div className="section-titleProductos ">
                                    <h2 className="section-title__titleProducSol">Características del servicio:
                                    </h2>
                                </div>

                         </div>
                            <p className="section-title__titleProducSoluc">
                                {caracteristica.map((caracteristica: any, index: any) => (
                                <li> {caracteristica}</li>
                            ))}
                            </p></>
                        )}
                         <div
                            className="tips2 slider2Soluciones"
                            style={{
                                backgroundImage: `url(${isMobile ? BASE_URL + item.fotomobile : BASE_URL + item.fotoweb})`
                            }}
                        />
                        
                        </>
                        )}
                        )}

                        
                </div>
        </section>
        
        <NewsLetter />
      

        <Cotizar />

      <Footer/>
      </div>
    </>
  );
}

export default Solutions;
