import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SideBar: React.FC = () => {

  return (
    <>
      <div className="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ width: "280px", height: "100vh" }}>
      <h4>Menu Principal</h4>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <a href="/admin" className="nav-link link-dark">
            Principal
          </a>
        </li>
        <li className="nav-item">
          <a href="#productos" className="nav-link link-dark" data-bs-toggle="collapse" aria-expanded="false">
            Productos
          </a>
          <div className="collapse" id="productos">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li className="nav-item">
                <Link to="/admin/productos" className="nav-link link-dark">
                  Listar
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/producto" className="nav-link link-dark">
                  Registrar
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <a href="#usuarios" className="nav-link link-dark" data-bs-toggle="collapse" aria-expanded="false">
            Usuarios
          </a>
          <div className="collapse" id="usuarios">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li className="nav-item">
                <Link to="/admin/usuarios" className="nav-link link-dark">
                  Listar
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/usuario" className="nav-link link-dark">
                  Registrar
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <a href="#Soluciones" className="nav-link link-dark" data-bs-toggle="collapse" aria-expanded="false">
            Soluciones
          </a>
          <div className="collapse" id="Soluciones">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li className="nav-item">
                <Link to="/admin/soluciones" className="nav-link link-dark">
                  Listar
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/solucion" className="nav-link link-dark">
                  Registrar
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <a href="/admin/subs" className="nav-link link-dark">
            Subscripciones
          </a>
        </li>
        <li className="nav-item">
          <a href="/admin/cotizaciones" className="nav-link link-dark">
            Cotizaciones
          </a>
        </li>
      </ul>
    </div>
    </>
  );
};

export default SideBar;