import React, { useEffect, useState } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { fetchItems } from './api';
import ItemList from './Components/ItemList';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from './Constants';

import { faFilePdf, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocumentList from './DocumentList';

const Producto: React.FC = () => {
    const { productId } = useParams();
    const [nombre, setNombre] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [caracteristicas, setCaracteristicas] = useState<string[]>([]);
    const [nuevaCaracteristica, setNuevaCaracteristica] = useState('');
    const [fotos, setFotos] = useState<File | null>(null); // Cambiado a tipo File
    const [activo, setActivo] = useState(true);
    const navigate = useNavigate();
    const [fotoURL, setFotoURL] = useState<string | null>(null); // Estado para la URL de previsualización
    const [documentos, setDocumentos] = useState<any[]>([]);

    useEffect(() => {
        // Si productId está presente, cargar los detalles del producto
        if (productId) {
          fetchProducto(productId);
          fetchDocumentos(productId);
        }
      }, [productId]);

    const fetchProducto = async (id: string) => {
        try {
          const response = await fetch(`https://lsaperu.com/backend/public/api/productos/${id}`);
          if (response.ok) {
            const data = await response.json();
            setNombre(data.nombre);
            setDescripcion(data.descripcion);
            setCaracteristicas(data.caracteristicas ? data.caracteristicas.split('@') : []);
            setFotos(data.fotos);
            setActivo(data.activo);
          } else {
            console.error('Error al cargar los detalles del producto');
          }
        } catch (error) {
          console.error('Error al cargar los detalles del producto:', error);
        }
      };

      const fetchDocumentos = async (id: string) => {
        try {
          const response = await fetch(`https://lsaperu.com/backend/public/api/documentos/${id}`);
          if (response.ok) {
            const data = await response.json();
            setDocumentos(data);
          } else {
            console.error('Error al cargar los documentos');
          }
        } catch (error) {
          console.error('Error al cargar los documentos:', error);
        } 
      };

  return (
    <>

      <div className="page-wrapper">
        <Header />


        <section className="project-two">
            <div className="project-two__container">
                <div className="section-title-np text-center">
                    
                    <h2 className="cuestomTitlePS">NUESTROS PRODUCTOS</h2>
                </div>
            </div>
        </section>






        <section className="work-details2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                       
                    </div>
                </div>
                <div className="work-detailsContacto__content">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 dflexcenter">
                            <div className="work-details__content-left2">
                                <h3 className="work-details__titleProducto">{nombre}</h3>
                                <p className="work-details__text-2">{descripcion}</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="project-one__img">
                                <img src={BASE_URL+fotos} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>

           
        <section className="work-details5">
            
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="section-titleProducto  text-center">
                            <h2 className="section-title__titleProducIndi">Características</h2>
                        </div>

                    </div>
                    <p className="section-title__titleProduccaracters">
                        {caracteristicas.map((caracteristica, index) => (
                        <li> {caracteristica}</li>
                    ))}</p>
                </div>
                <div className="tips3">
                    {/*<img  src="/assets/images/backgrounds/prodcuto indit_2.png" alt="" />*/}
                </div>
                {documentos.length > 0  &&  (<DocumentList documentos={documentos} />
                )}

            </div>
            
            </section>

      <Footer/>
      </div>
    </>
  );
}

export default Producto;
