
import React, { useState } from 'react';

const Cotizar: React.FC = () => {

    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [celular, setCelular] = useState('');
    const [diametro, setDiametro] = useState('');
    const [longitud, setLongitud] = useState('');
    const [conexion, setConexion] = useState('');
    const [medidas, setMedidas] = useState('');
    const [temperatura, setTemperatura] = useState('');
    const [conCabezal, setConCabezal] = useState('');
    const [conCable, setConCable] = useState('');
    const [comentario, setComentario] = useState('');
    const [error, setError] = useState('');


    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!nombre || !correo || !celular || !comentario) {
            setError('Por favor, complete todos los campos obligatorios.');
            return;
        }

        const formData = {
            nombre,
            correo,
            celular,
            diametro,
            longitud,
            conexion,
            medidas,
            temperatura,
            conCabezal,
            conCable,
            comentario,
        };

        try {
            const response = await fetch('https://lsaperu.com/backend/public/api/cotizaciones', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                alert('Cotización enviada con éxito');
                // Limpiar el formulario
                setNombre('');
                setCorreo('');
                setCelular('');
                setDiametro('');
                setLongitud('');
                setConexion('');
                setMedidas('');
                setTemperatura('');
                setConCabezal('');
                setConCable('');
                setComentario('');
                setError('');
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Error al enviar la cotización.');
            }
        } catch (error) {
            setError('Error al enviar la cotización.');
        }
    };

    return (
        <>
            <section className="work-details">
                <div className="container contCotiza">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title2 text-center">
                                <h2 className="section-title__titleProducIndi">COTIZA AHORA</h2>
                            </div>
                        </div>
                        <p className="section-texto__textop2">
                            Envíanos tus requerimientos y especificaciones, completando el siguiente formulario
                        </p>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="work-detailsContacto__content">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="form-group96">
                                        <label>Nombre:</label>
                                        <input
                                            type="text"
                                            id="nombre"
                                            className="form-control96"
                                            value={nombre}
                                            onChange={(e) => setNombre(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group96">
                                        <label>Correo electrónico:</label>
                                        <input
                                            type="email"
                                            id="correo"
                                            className="form-control96"
                                            value={correo}
                                            onChange={(e) => setCorreo(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group96">
                                        <label>Número de celular:</label>
                                        <input
                                            type="text"
                                            id="celular"
                                            className="form-control96"
                                            value={celular}
                                            onChange={(e) => setCelular(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group96">
                                        <label>Diámetro del buldo:</label>
                                        <input
                                            type="text"
                                            id="diametro"
                                            className="form-control96"
                                            value={diametro}
                                            onChange={(e) => setDiametro(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group96">
                                        <label>Longitud del buldo:</label>
                                        <input
                                            type="text"
                                            id="longitud"
                                            className="form-control96"
                                            value={longitud}
                                            onChange={(e) => setLongitud(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group96">
                                        <label>Conexión a proceso:</label>
                                        <input
                                            type="text"
                                            id="conexion"
                                            className="form-control96"
                                            value={conexion}
                                            onChange={(e) => setConexion(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group96">
                                        <label>Medidas y tipo de roscas:</label>
                                        <input
                                            type="text"
                                            id="medidas"
                                            className="form-control96"
                                            value={medidas}
                                            onChange={(e) => setMedidas(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group96">
                                        <label>Temperatura de uso aplicación:</label>
                                        <input
                                            type="text"
                                            id="temperatura"
                                            className="form-control96"
                                            value={temperatura}
                                            onChange={(e) => setTemperatura(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5">
                                    <div className="question">
                                        <div className="column">
                                            <label>¿Con cabezal?</label>
                                        </div>
                                        <div className="column">
                                            <input
                                                type="radio"
                                                id="headYes"
                                                name="headQuestion"
                                                value="yes"
                                                checked={conCabezal === 'yes'}
                                                onChange={(e) => setConCabezal(e.target.value)}
                                            />
                                            <label>Sí</label><br />
                                            <input
                                                type="radio"
                                                id="headNo"
                                                name="headQuestion"
                                                value="no"
                                                checked={conCabezal === 'no'}
                                                onChange={(e) => setConCabezal(e.target.value)}
                                            />
                                            <label>No</label>
                                        </div>
                                    </div>
                                    <div className="question">

                                        <div className="column">
                                            <label>¿Con cable?</label>
                                        </div>
                                        <div className="column">
                                            <input
                                                type="radio"
                                                id="cableYes"
                                                name="cableQuestion"
                                                value="yes"
                                                checked={conCable === 'yes'}
                                                onChange={(e) => setConCable(e.target.value)}
                                            />
                                            <label>Sí</label><br />
                                            <input
                                                type="radio"
                                                id="cableNo"
                                                name="cableQuestion"
                                                value="no"
                                                checked={conCable === 'no'}
                                                onChange={(e) => setConCable(e.target.value)}
                                            />
                                            <label>No</label>
                                        </div>
                                    </div>
                                    <div className="comentario-container36">

                                        <label>Detalle o Comentario:</label>
                                        <textarea
                                            className="comentario-input2"
                                            value={comentario}
                                            onChange={(e) => setComentario(e.target.value)}
                                        ></textarea>
                                    </div>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                    <button type="submit" className="enviar-btn3">
                                        Enviar
                                    </button>
                                </div>


                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default Cotizar;