import React, { useEffect, useState } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Cotizar from './Components/Cotiza';

const Contact: React.FC = () => {


  return (
    <>

      <div className="page-wrapper">
        <Header />


        <section className="main-slider clearfix">
            <div className="swiper-container thm-swiper__slider" data-swiper-options='{"slidesPerView": 1, "loop": true,
                "effect": "fade",
                "pagination": {
                "el": "#main-slider-pagination",
                "type": "bullets",
                "clickable": true
                },
                "navigation": {
                "nextEl": "#main-slider__swiper-button-next",
                "prevEl": "#main-slider__swiper-button-prev"
                },
                "autoplay": {
                "delay": 5000
                }}'>
                <div className="swiper-wrapper">

                    <div className="swiper-slide">
                        <div className="image-layer slider1Contactanos" >
                            </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="main-slider__content">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
        </section>

        <section className="work-detailsContactanos">
            <div className="cuestomTitle">
                CONTÁCTANOS
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                       
                    </div>
                </div>
                <div className="work-detailsContacto__content">
                    <div className="row">
                    
                    </div>
                </div>
                
            </div>
        </section>

        <section className="work-detailscontact2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                       
                    </div>
                </div>
                <div className="work-detailsContacto__content">
                    <div className="row">
                    
                        <div className="col-xl-12 col-lg-12">
                            <div className="work-detailsContacto__content-leftSol">
                   
                            <h2 className="work-details__text-1"><span className="colorCalidad">Soporte</span></h2>
                                <p className="work-details__textsolucion-2">Estamos interesados en ofrecer la mejor experiencia a nuestros clientes. Por ello, ponemos a su disposición los siguientes canales de contácto.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tips slider2Contactanos">
                </div>
                <p className="work-details__textsolucion-3"> Nuestro equipo se pondrá en contacto con usted en la brevedad posible.</p>


            </div>
        </section>
      
        <Cotizar />

      <Footer/>
      </div>
    </>
  );
}

export default Contact;
