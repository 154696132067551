import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SideBar from './Components/SideBar';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { BASE_URL, BASE_URL_DOCUMENTS } from './Constants';
import Header from './Components/Header';
import Footer from './Components/Footer';
import DataTable from 'react-data-table-component';

interface User {
    id: number;
    name: string;
    email: string;
    email_verified_at: null | string;
    created_at: string;
    updated_at: string;
  }
  
  interface ProductProps {
    productId?: number; // Id del producto existente, opcional
  }
  
  library.add(fas, fab);

const ListaDocumentos: React.FC<ProductProps> = () => {
  const [documentos, setDocumentos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [nombre, setNombre] = useState('');

  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const { productId } = useParams();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      setUser(JSON.parse(token));
    }
  }, [navigate]);

  useEffect(() => {
    if(productId){
        fetchDocumentos();
        fetchProducto(productId);
    }
  }, [productId]);

  const fetchProducto = async (id: string) => {
    try {
      const response = await fetch(`https://lsaperu.com/backend/public/api/productos/${id}`);
      if (response.ok) {
        const data = await response.json();
        setNombre(data.nombre);
      } else {
        console.error('Error al cargar los detalles del producto');
      }
    } catch (error) {
      console.error('Error al cargar los detalles del producto:', error);
    }
  };

  const fetchDocumentos = async () => {
    try {
      const response = await fetch(`https://lsaperu.com/backend/public/api/documentos/${productId}`);
      if (response.ok) {
        const data = await response.json();
        setDocumentos(data);
      } else {
        console.error('Error al cargar los documentos');
      }
    } catch (error) {
      console.error('Error al cargar los documentos:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEliminarDocumento = async (id: number) => {
    try {
      const response = await fetch(`https://lsaperu.com/backend/public/api/documentos/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchDocumentos();
      } else {
        console.error('Error al eliminar el documento');
      }
    } catch (error) {
      console.error('Error al eliminar el documento:', error);
    }
  };

  const columns = [
    {
      name: 'Acciones',
      cell: (row: any) => (
        <div>
          <Button variant="warning" className="me-2" onClick={() => navigate(`/admin/documento/${productId}/${row.id}`)}>
            <FontAwesomeIcon icon={['fas', 'pencil']} size="1x" />
          </Button>
          <Button variant="danger" onClick={() => handleEliminarDocumento(row.id)}>
            <FontAwesomeIcon icon={['fas', 'trash']} size="1x" />
          </Button>
        </div>
      ),
      width: '80px',
    },
    {
      name: 'ID',
      selector: (row: any) => row.id,
      sortable: true,
      width: '60px',
    },
    {
      name: 'Nombre de Archivo',
      selector: (row: any) => row.nombre_archivo,
      sortable: true,
    },
    {
      name: 'URL de Archivo',
      selector: (row: any) => (
        <embed src={BASE_URL_DOCUMENTS+ row.url_archivo} rel="noopener noreferrer" />
      ),
      sortable: false,
    },
    {
      name: 'Fecha de Creación',
      selector: (row: any) => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
    },
  ];

  if (!user) return null;

  return (
    <div className="page-wrapper">
      <Header />
      <div className="d-flex">
        <SideBar />
        <div className="flex-grow-1 p-3">
          <div className="container mt-5">
            <h2>Lista de Documentos para {nombre}</h2>
            <hr />
            <Button variant="primary" className="mb-3" onClick={() => navigate('/admin/documento/'+productId+'/0')}>
              Crear Nuevo Documento
            </Button>
            {loading ? (
              <p>Cargando Documentos...</p>
            ) : (
              <DataTable
                columns={columns}
                data={documentos}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                highlightOnHover
                pointerOnHover
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ListaDocumentos;
