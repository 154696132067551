import React from 'react';

const Footer: React.FC = () => {
    return (
        <>
            <footer className="site-footer">
                <div className="container">
                    <div className="site-footer__top">
                        <div className="row aligncenter">
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                <div className="footer-widget__column footer-widget__about">
                                    <div className="main-header__top-address">
                                        <a href="index.html"><img src="/assets/images/resources/002.LOGO.LSA.PERU.png" alt=""
                                            className='footerLogo' /></a>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                <div className="footer-widget__column footer-widget__explore clearfix">
                                    <h3 className="footer-widget__title">Ubícanos</h3>
                                    <ul className="footer-widget__explore-list list-unstyled clearfix">
                                        <li> <i className="fas fa-map-marker-alt"></i> Av. Principal 441 - Oficina 304</li>
                                        <li> Surquillo - Lima</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                <div className="footer-widget__column footer-widget__contact">
                                    <h3 className="footer-widget__title">Contáctanos</h3>
                                    <ul className="list-unstyled footer-widget__contact-list listaMobile">

                                        <li>
                                            <div className="icon">
                                                <i className="fas fa-phone-alt"></i>
                                            </div>
                                            <div className="text">
                                                <p><a href="tel:+51945425276">+51 945 425 276</a></p>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="icon">
                                                <i className="fa fa-envelope"></i>
                                            </div>
                                            <div className="text">
                                                <p><a href="mailto:needhelp@company.com ">ventas@lsaperu.com</a></p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>


                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                <div className="footer-widget__column footer-widget__newsletter clearfix">
                                    <h3 className="footer-widget__title">Síguenos</h3>
                                    <form className="footer-widget__newsletter-form">
                                        <div className="site-footer__social iconMobile">
                                            <a href="https://www.facebook.com/profile.php?id=100088485326446" target='_blank'><i className="fab fa-facebook"></i></a>
                                            <a href="https://www.linkedin.com/company/91704465/admin/feed/posts/"  target='_blank'><i className="fab fa-linkedin"></i></a>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </footer>
        </>
    );
};

export default Footer;