import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBar from './Components/SideBar';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { BASE_URL } from './Constants';
import Header from './Components/Header';
import Footer from './Components/Footer';
import DataTable from 'react-data-table-component';

interface User {
    id: number;
    name: string;
    email: string;
    email_verified_at: null | string;
    created_at: string;
    updated_at: string;
  }
  
  library.add(fas, fab);

const ListaSoluciones: React.FC = () => {
  const [productos, setProductos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      // Aquí asumimos que guardaste los datos del usuario en localStorage o los recuperas de alguna API
        setUser(JSON.parse(token));
    }
  }, [navigate]);

  useEffect(() => {
    fetchProductos();
  }, []);

  const fetchProductos = async () => {
    try {
      const response = await fetch('https://lsaperu.com/backend/public/api/soluciones/all');
      if (response.ok) {
        const data = await response.json();
        setProductos(data);
      } else {
        console.error('Error al cargar las soluciones');
      }
    } catch (error) {
      console.error('Error al cargar los soluciones:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEliminarProducto = async (id: number) => {
    try {
      const response = await fetch(`https://lsaperu.com/backend/public/api/soluciones/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        // Refrescar la lista de productos después de eliminar
        fetchProductos();
      } else {
        console.error('Error al eliminar el producto');
      }
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  };

  const truncateString = (str: string, num = 70) => {
    if (str.length > num) {
      return str.slice(0, num) + '...';
    } else {
      return str;
    }
  };

  if (!user) return null;

  const columns = [
    {
      name: 'Acciones',
      cell: (row: any) => (
        <div>
          <Button variant="warning" className="me-2" onClick={() => navigate('/admin/solucion/' + row.id)}>
          <FontAwesomeIcon icon={['fas', 'pencil']} size="1x" />
          </Button>
          <Button variant="danger" onClick={() => handleEliminarProducto(row.id)}>
          <FontAwesomeIcon icon={['fas', 'trash']} size="1x" />
          </Button>
        </div>
      ),
      
      width: '80px',
    },
    {
      name: 'ID',
      selector: (row: any) => row.id,
      sortable: true,
      width: '60px',
    },
    {
      name: 'Nombre',
      selector: (row: any) => row.titulo,
      sortable: true,
    },
    {
        name: 'Beneficios',
        selector: (row: any) => (
          <ul>
            {row.beneficios ? row.beneficios.split('@').map((caracteristica: string, index: number) => (
              <li key={index}>{truncateString(caracteristica)}</li>
            )) : 'N/A'}
          </ul>
        ),
        sortable: false,
      },
    {
      name: 'Descripción',
      selector: (row: any) => truncateString(row.descripcion),
      sortable: true,
    },
    {
      name: 'Características',
      selector: (row: any) => (
        <ul>
          {row.caracteristicas ? row.caracteristicas.split('@').map((caracteristica: string, index: number) => (
            <li key={index}>{truncateString(caracteristica)}</li>
          )) : 'N/A'}
        </ul>
      ),
      sortable: false,
    },
    {
      name: 'Foto Web',
      selector: (row: any) => <img src={BASE_URL + row.fotoweb} style={{ width: '70px' }} alt="Producto" />,
      sortable: false,
      width: '100px',
    },
    {
        name: 'Foto Mobile',
        selector: (row: any) => <img src={BASE_URL + row.fotomobile} style={{ width: '70px' }} alt="Producto" />,
        sortable: false,
        width: '100px',
      }
   
  ];

  return (
    <div className="page-wrapper">

    <Header />
    <div className="d-flex">
    <SideBar />
    <div className="flex-grow-1 p-3">
        <div className="container mt-5">
      <h2>Lista de Soluciones</h2>
      <hr />
      <Button variant="primary" className="mb-3" onClick={() => navigate('/admin/solucion')}>
        Crear Nueva Solución
      </Button>
      {loading ? (
        <p>Cargando Soluciones...</p>
      ) : (
          <DataTable
                columns={columns}
                data={productos}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                highlightOnHover
                pointerOnHover
              />
      )}
    </div>
        </div>
    </div>    
    <Footer/>
      </div>  
  );
};

export default ListaSoluciones;