import React, { useState, useEffect } from 'react';
import SideBar from './Components/SideBar';
import Header from './Components/Header';
import Footer from './Components/Footer';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';

interface Subs {
  id: number;
  email: string;
  hora_de_la_subscripcion: string;
  created_at: string;
  updated_at: string;
}

const ListaSubs: React.FC = () => {
  const [usuarios, setUsuarios] = useState<Subs[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsuarios();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  const fetchUsuarios = async () => {
    try {
      const response = await fetch('https://lsaperu.com/backend/public/api/subscripciones');
      if (response.ok) {
        const data = await response.json();
        setUsuarios(data);
      } else {
        console.error('Error al cargar los usuarios');
      }
    } catch (error) {
      console.error('Error al cargar los usuarios:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: 'ID',
      selector: (row: Subs) => row.id,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: Subs) => row.email,
      sortable: true,
    },
    {
      name: 'Fecha de Registro',
      selector: (row: Subs) => row.hora_de_la_subscripcion,
      sortable: true,
    },
  ];

  return (
    
    <div className="page-wrapper">
    <Header />
    <div className="d-flex">
      <SideBar />
      <div className="flex-grow-1 p-3">
        <div className="container mt-5">
          <h2>Lista de Subscriptores</h2>
          <hr />
          
          {loading ? (
              <p>Cargando Subs...</p>
            ) : (
              <DataTable
                columns={columns}
                data={usuarios}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                highlightOnHover
                pointerOnHover
              />
            )}
        </div>
      </div>
    </div>
    <Footer/>
 </div>
  );
};

export default ListaSubs;
