import React, { useState, useEffect } from 'react';
import SideBar from './Components/SideBar';
import Header from './Components/Header';
import Footer from './Components/Footer';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';

interface Cotizacion {
  id: number;
  nombre: string;
  correo: string;
  celular: string;
  diametro: string;
  longitud: string;
  conexion: string;
  medidas: string;
  temperatura: string;
  cabezal: boolean;
  cable: boolean;
  comentario: string;
  created_at: string;
  updated_at: string;
}

const ListaCotizaciones: React.FC = () => {
  const [cotizaciones, setCotizaciones] = useState<Cotizacion[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCotizaciones();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  const fetchCotizaciones = async () => {
    try {
      const response = await fetch('https://lsaperu.com/backend/public/api/cotizaciones');
      if (response.ok) {
        const data = await response.json();
        setCotizaciones(data);
      } else {
        console.error('Error al cargar las cotizaciones');
      }
    } catch (error) {
      console.error('Error al cargar las cotizaciones:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: 'ID',
      selector: (row: Cotizacion) => row.id,
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: (row: Cotizacion) => row.nombre,
      sortable: true,
    },
    {
      name: 'Correo',
      selector: (row: Cotizacion) => row.correo,
      sortable: true,
    },
    {
      name: 'Celular',
      selector: (row: Cotizacion) => row.celular,
      sortable: true,
    },
    {
      name: 'Diámetro',
      selector: (row: Cotizacion) => row.diametro,
      sortable: true,
    },
    {
      name: 'Longitud',
      selector: (row: Cotizacion) => row.longitud,
      sortable: true,
    },
    {
      name: 'Conexión',
      selector: (row: Cotizacion) => row.conexion,
      sortable: true,
    },
    {
      name: 'Medidas',
      selector: (row: Cotizacion) => row.medidas,
      sortable: true,
    },
    {
      name: 'Temperatura',
      selector: (row: Cotizacion) => row.temperatura,
      sortable: true,
    },
    {
      name: 'Cabezal',
      selector: (row: Cotizacion) => row.cabezal ? 'Sí' : 'No',
      sortable: true,
    },
    {
      name: 'Cable',
      selector: (row: Cotizacion) => row.cable ? 'Sí' : 'No',
      sortable: true,
    },
    {
      name: 'Comentario',
      selector: (row: Cotizacion) => row.comentario,
      sortable: true,
    },
    {
      name: 'Fecha de Registro',
      selector: (row: Cotizacion) => row.created_at,
      sortable: true,
    },
  ];

  return (
    <div className="page-wrapper">
      <Header />
      <div className="d-flex">
        <SideBar />
        <div className="flex-grow-1 p-3">
          <div className="container mt-5">
            <h2>Lista de Cotizaciones</h2>
            <hr />
            {loading ? (
              <p>Cargando Cotizaciones...</p>
            ) : (
              <DataTable
                columns={columns}
                data={cotizaciones}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                highlightOnHover
                pointerOnHover
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ListaCotizaciones;