import React, { useEffect, useState } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { fetchItems, fetchItemsAll } from './api';
import ItemList from './Components/ItemList';
import Cotizar from './Components/Cotiza';

const Products: React.FC = () => {

    const [items, setItems] = useState([]);
    const [altura, setAltura] = useState(false);

    useEffect(() => {
        const getItems = async () => {
        try {
            const data = await fetchItemsAll();
            setItems(data);
            setAltura(true);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
        };

        getItems();
    }, []);

  return (
    <>

      <div className="page-wrapper">
        <Header />


        <section className="project-two">
          <div className="project-two__container">
            <div className="section-title-np text-center">

              <h2 className="cuestomTitleP">NUESTROS PRODUCTOS</h2>
            </div>

            <div className="project-two__bottomHome">
                <ItemList items={items} altura={altura} isHome={false}/>
            </div>
          </div>
        </section>

        <section className="cta-one" style={{marginTop:"100px"}}>
          <div className="container">
            <div className="cta-one__inner">
              <div className="cta-one__left">
                <h3 className="cta-one__title">Contáctanos</h3>
                <p className="cta-one__sub-title">Te brindamos soluciones a tu medida</p>
              </div>
              <div className="footer-widget__newsletter-input-box">
                <input type="email" placeholder="Enter Email Address" name="email"  />
                <button type="submit" className="footer-widget__newsletter-btn thm-btn">Enviar</button>
              </div>
            </div>
          </div>
        </section>

        <Cotizar />

      <Footer/>
      </div>
    </>
  );
}

export default Products;
