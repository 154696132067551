
import React, { useState } from 'react';

const NewsLetter: React.FC = () => {

    const [email, setEmail] = useState('');


    const handleSubscribe = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
          const response = await fetch('https://lsaperu.com/backend/public/api/subscripciones', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
          });
          if (response.ok) {
            alert('Suscripción exitosa');
            setEmail('');
          } else {
            alert('Error al suscribirse');
          }
        } catch (error) {
          console.error('Error al enviar la suscripción:', error);
          alert('Error al suscribirse');
        }
      };

  return (
    <>
 <section className="cta-one">
          <div className="container">
            <div className="cta-one__inner">
              <div className="cta-one__left">
                <h3 className="cta-one__title">Contáctanos</h3>
                <p className="cta-one__sub-title">Te brindamos soluciones a tu medida</p>
              </div>
              <form className="footer-widget__newsletter-input-box" onSubmit={handleSubscribe}>
                <input
                  type="email"
                  placeholder="Enter Email Address"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="footer-widget__newsletter-btn thm-btn">Enviar</button>
              </form>
            </div>
          </div>
        </section>
    </>
  );
};

export default NewsLetter;