import React, { useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await fetch('https://lsaperu.com/backend/public/api/ingresar', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: username, password }),
    });

    const data = await response.json();

    if (response.ok) {
        localStorage.setItem('token', JSON.stringify(data.user));
      navigate('/admin');  // Usar react-router-dom para redirigir
    } else {
      setError('Contraseña incorrecta o usuario no encontrado');
    }
  };

  return (
    <div className="page-wrapper">
    <Header />
    <div className="container">
      <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Iniciar Sesión</h5>
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">Usuario</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Contraseña</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary">Ingresar</button>
                {error && <div className="alert alert-danger mt-2">{error}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Login;
