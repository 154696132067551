import React, { useEffect, useState } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { fetchItems } from './api';
import ItemList from './Components/ItemList';
import NewsLetter from './Components/NewsLetter';

const About: React.FC = () => {

    const useIsMobile = (breakpoint = 768) => {
        const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);
      
        useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth < breakpoint);
          };
      
          window.addEventListener('resize', handleResize);
      
          // Limpia el evento cuando el componente se desmonta
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, [breakpoint]);
      
        return isMobile;
      };
    
      const isMobile = useIsMobile();

  return (
    <>

      <div className="page-wrapper">
        <Header />
        <section className="main-slider clearfix">
            <div className="swiper-container thm-swiper__slider" data-swiper-options='{"slidesPerView": 1, "loop": true,
                "effect": "fade",
                "pagination": {
                "el": "#main-slider-pagination",
                "type": "bullets",
                "clickable": true
                },
                "navigation": {
                "nextEl": "#main-slider__swiper-button-next",
                "prevEl": "#main-slider__swiper-button-prev"
                },
                "autoplay": {
                "delay": 5000
                }}'>
                <div className="swiper-wrapper">

                    <div className="swiper-slide">
                        <div className="image-layer slider1Nosotros" >
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="work-detailsNosotros">
            <div className="cuestomTitleHistory">
                NUESTRA HISTORIA
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                       
                    </div>
                </div>
                <div className="work-detailsContacto__content">
                    <div className="row">
                    
                        <div className="col-xl-12 col-lg-12">
                            <div className="work-detailsContacto__content-left">
                                <p className="work-details__textNuestraHistoria-1">Somos una empresa especializada en la venta de instrumentos a medida para proyectos industriales, contribuyendo con su productividad.
                                    Contamos con más de 6 años de experiencia en el campo, brindando un servicio de confianza, calidad, eficaz e innovador; además, cumplimos con las necesidades y especificaciones de nuestros clientes.
                                    <br /> <br />Apoyamos a empresas líderes del sector agroindustrial, minero, textil, de generación eléctrica, entre otras.
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="work-detailsfot">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">

                       
                    </div>
                </div>
                <div className="work-detailsContacto__content">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">
                            <div className="project-one__img">
                                <img src="assets/images/resources/Mision.png" alt="" />
                            </div>
                        </div>

                        <div className="col-xl-8 col-lg-8">
                            <div className="work-detailsContacto__content-left">
                                <h3 className="work-details__titleNosotros">Misión</h3>
                                <p className="work-details__text-2">Brindarle a nuestros clientes soluciones
                                    especializadas de automatización e instrumentación como termocuplas,
                                    manómetros, para cubrir a largo plazo las necesidades de las diferentes empresas del rubro insdustrial con eficacia, confiabilidad y calidad que nos caracteriza.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>

        {!isMobile && (<section className="work-details2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                       
                    </div>
                </div>
                <div className="work-detailsContacto__content">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <div className="work-details__content-left">
                                <h3 className="work-details__titleNosotros">Visión</h3>
                                <p className="work-details__text-2">Ser la empresa líder a nivel nacional en 
                                    soluciones especializadas de automatización 
                                    e instrumentación, guiada por profesionales 
                                    capacitados, siendo así reconocidos por la 
                                    excelencia de nuestros productos y servicios 
                                    que se adaptan a las necesidades del 
                                    mercado actual, manteniendo estándares de 
                                    calidad internacionales y ofreciendo 
                                    soluciones innovadoras tanto a nuestros 
                                    clientes potenciales como fidelizados.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="project-one__img">
                                <img src="assets/images/resources/Vision.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>)}

        {isMobile && (<section className="work-detailsfot">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">

                       
                    </div>
                </div>
                <div className="work-detailsContacto__content">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">
                            <div className="project-one__img">
                            <img src="assets/images/resources/Vision.png" alt="" />
                            </div>
                        </div>

                        <div className="col-xl-8 col-lg-8">
                            <div className="work-detailsContacto__content-left">
                                <h3 className="work-details__titleNosotros">Visión</h3>
                                <p className="work-details__text-2">Ser la empresa líder a nivel nacional en 
                                    soluciones especializadas de automatización 
                                    e instrumentación, guiada por profesionales 
                                    capacitados, siendo así reconocidos por la 
                                    excelencia de nuestros productos y servicios 
                                    que se adaptan a las necesidades del 
                                    mercado actual, manteniendo estándares de 
                                    calidad internacionales y ofreciendo 
                                    soluciones innovadoras tanto a nuestros 
                                    clientes potenciales como fidelizados.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>)}

        <NewsLetter />

      <Footer/>
      </div>
    </>
  );
}

export default About;
