import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import SideBar from './Components/SideBar';
import Footer from './Components/Footer';
import Header from './Components/Header';

interface UsuarioProps {
  userId?: number; // Id del usuario existente, opcional
}

interface User {
  id: number;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
}

const Usuario: React.FC<UsuarioProps> = () => {
  const { userId } = useParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      setUser(JSON.parse(token));
    }
  }, [navigate]);

  useEffect(() => {
    // Si userId está presente, cargar los detalles del usuario
    if (userId) {
      fetchUser(userId);
    }
  }, [userId]);

  const fetchUser = async (id: string) => {
    try {
      const response = await fetch(`https://lsaperu.com/backend/public/api/usuarios/${id}`);
      if (response.ok) {
        const data = await response.json();
        setName(data.user.name);
        setEmail(data.user.email);
      } else {
        console.error('Error al cargar los detalles del usuario');
      }
    } catch (error) {
      console.error('Error al cargar los detalles del usuario:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const url = userId ? `https://lsaperu.com/backend/public/api/usuarios/${userId}` : 'https://lsaperu.com/backend/public/api/registrar';
    const method = userId ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, password, password_confirmation: confirmPassword }),
      });
      if (response.ok) {
        const data = await response.json();
        navigate("/admin/usuarios");
      } else {
        console.error('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
    }
  };

  if (!user) return null;

  return (
    <div className="page-wrapper">
    <Header />
    <div className="d-flex">
      <SideBar />
      <div className="flex-grow-1 p-3">
        <div className="container mt-5">
          <h2>{userId ? 'Editar Usuario' : 'Crear Nuevo Usuario'}</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" placeholder="Ingrese el nombre del usuario" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Ingrese el email del usuario" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control type="password" placeholder="Ingrese la contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formConfirmPassword">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <Form.Control type="password" placeholder="Confirme la contraseña" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            </Form.Group>
            <Button variant="primary" type="submit">
              {userId ? 'Actualizar Usuario' : 'Crear Usuario'}
            </Button>
          </Form>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Usuario;