import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBar from './Components/SideBar';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import DataTable from 'react-data-table-component';

interface User {
  id: number;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
}

library.add(fas, fab);

const ListaUsuarios: React.FC = () => {
  const [usuarios, setUsuarios] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUsuarios();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  const fetchUsuarios = async () => {
    try {
      const response = await fetch('https://lsaperu.com/backend/public/api/usuarios');
      if (response.ok) {
        const data = await response.json();
        setUsuarios(data.users);
      } else {
        console.error('Error al cargar los usuarios');
      }
    } catch (error) {
      console.error('Error al cargar los usuarios:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEliminarUsuario = async (id: number) => {
    try {
      const response = await fetch(`https://lsaperu.com/backend/public/api/usuarios/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        // Refrescar la lista de usuarios después de eliminar
        fetchUsuarios();
      } else {
        console.error('Error al eliminar el usuario');
      }
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
    }
  };

  const columns = [
    {
      name: 'ID',
      selector: (row: User) => row.id,
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: (row: User) => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: User) => row.email,
      sortable: true,
    },
    {
      name: 'Fecha de Registro',
      selector: (row: User) => row.created_at,
      sortable: true,
    },
    {
      name: 'Opciones',
      cell: (row: User) => (
        <div>
          <Button  variant="warning" className="me-2" onClick={() => navigate(`/admin/usuario/${row.id}`)} >
            <FontAwesomeIcon icon={['fas', 'pencil']} size="1x" />
          </Button>
          <Button  variant="danger" onClick={() => handleEliminarUsuario(row.id)}>
            <FontAwesomeIcon icon={['fas', 'trash']} size="1x" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    
    <div className="page-wrapper">
    <Header />
    <div className="d-flex">
      <SideBar />
      <div className="flex-grow-1 p-3">
        <div className="container mt-5">
          <h2>Lista de Usuarios</h2>
          <hr />
          <Button variant="primary" className="mb-3" onClick={() => navigate('/admin/usuario')}>
          Crear Nuevo Usuario
        </Button>
          {loading ? (
            <p>Cargando usuarios...</p>
          ) : (
            <DataTable
              columns={columns}
              data={usuarios}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 25, 50]}
              highlightOnHover
              pointerOnHover
            />
          )}
        </div>
      </div>
    </div>
    <Footer/>
 </div>
  );
};

export default ListaUsuarios;
